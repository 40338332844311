<template>
    <div>
        <v-data-table
            :headers="headers"
            :height="listHeight"
            :items="itemList"
            :items-per-page="-1"
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            group-by="Product.supplier"
            hide-default-footer
            item-key="Product.id"
            multi-sort
            sort-by="Product.product"
            show-group-by
        >
            <template v-slot:item.Product.supplier="{ item }">
                <span class="text-no-wrap">{{ item.Product.supplier }}</span>
            </template>
            <template v-slot:item.Product.product="{ item }">
                <div class="d-flex flex-column fill-height justify-start">
                    <v-chip class="text-no-wrap" x-small v-if="item.Product.product">
                        <span v-highlight="highlightTextObject">{{ item.Product.product }}</span>
                    </v-chip>
                </div>
            </template>
            <template v-slot:item.Product.design="{ item }">
                <template v-if="item.Product.design">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.design" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.species="{ item }">
                <template v-if="item.Product.species">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.species" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.grade="{ item }">
                <template v-if="item.Product.grade">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.grade" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.origin_certification="{ item }">
                <template v-if="item.Product.origin_certification">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.origin_certification" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.market_certification="{ item }">
                <template v-if="item.Product.market_certification">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.market_certification" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.thickness="{ item }">
                <template v-if="item.Product.thickness">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.thickness" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.width="{ item }">
                <template v-if="item.Product.width">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.width" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.length="{ item }">
                <template v-if="item.Product.length">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.length" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.moisture_content="{ item }">
                <template v-if="item.Product.moisture_content">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.moisture_content" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.density="{ item }">
                <template v-if="item.Product.density">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.density" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.surface="{ item }">
                <template v-if="item.Product.surface">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.surface" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.composition="{ item }">
                <template v-if="item.Product.composition">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.composition" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.glue="{ item }">
                <template v-if="item.Product.glue">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.glue" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.finishing="{ item }">
                <template v-if="item.Product.finishing">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.finishing" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.edge="{ item }">
                <template v-if="item.Product.edge">
                    <div class="d-flex flex-column fill-height justify-start">
                        <v-chip class="text-no-wrap" :class="idx > 0 ? 'my-1' : ''" x-small v-for="(option, idx) in item.Product.edge" :key="option">
                            <span v-highlight="highlightTextObject">{{ option }}</span>
                        </v-chip>
                    </div>
                </template>
            </template>
            <template v-slot:item.Product.comments="{ item }">
                <v-chip class="text-no-wrap" x-small v-if="item.Product.comments">{{ item.Product.comments }}</v-chip>
            </template>
        </v-data-table>
    </div>
</template>

<script>

import highlight from 'vue-highlight-text/public/directive.min'

export default {
    name: 'SupplierProductSearchList',
    props: ['itemList','listHeight','keyWords'],
    data() {
        return {
            highlightTextObject: {
                keyword: [],
                sensitive: false,
                overWriteStyle: {
                    color: 'black',
                    backgroundColor: 'yellow'
                }
            },
        }
    },
    directives: {
        highlight: highlight
    },
    computed: {
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.supplier'),
                    value: 'Product.supplier',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.product'),
                    value: 'Product.product',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 3,
                    text: this.$t('message.designProfile'),
                    value: 'Product.design',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 4,
                    text: this.$t('message.species'),
                    value: 'Product.species',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 5,
                    text: this.$t('message.density'),
                    value: 'Product.density',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 6,
                    text: this.$t('message.composition'),
                    value: 'Product.composition',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 7,
                    text: this.$t('message.glue'),
                    value: 'Product.glue',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 8,
                    text: this.$t('message.surfaceEdges'),
                    value: 'Product.surface',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 9,
                    text: this.$t('message.finishing'),
                    value: 'Product.finishing',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 10,
                    text: this.$t('message.grades'),
                    value: 'Product.grade',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 11,
                    text: this.$t('message.mc'),
                    value: 'Product.moisture_content',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 12,
                    text: this.$t('message.thickness'),
                    value: 'Product.thickness',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 13,
                    text: this.$t('message.width'),
                    value: 'Product.width',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 14,
                    text: this.$t('message.length'),
                    value: 'Product.length',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 15,
                    text: this.$t('message.originCertification'),
                    value: 'Product.origin_certification',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 16,
                    text: this.$t('message.marketCertification'),
                    value: 'Product.market_certification',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                },
                {
                    id: 17,
                    text: this.$t('message.comments'),
                    value: 'Product.comments',
                    class: 'light-green lighten-3 pa-1',
                    groupable: false,
                    sortable: true,
                    searchable: true
                }
            ]
        }
    },
    watch: {
        keyWords(val) {
            console.log(val)
            this.highlightTextObject.keyword = val
        }
    },
    mounted() {
        this.$emit('export-fields-loaded', this.headers)
    }
}
</script>

<style scoped>
.v-data-table {
    overflow-x: auto;
}
.v-data-table::v-deep td.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
    font-size: 12px !important;
}
.v-data-table::v-deep .v-row-group__header td{
    font-size: 12px !important;
}
.v-data-table::v-deep th {
    white-space: nowrap;
}
.v-data-table::v-deep td .v-chip {
    padding: 0 8px !important;
}
.v-data-table::v-deep td .v-chip__content {
    font-size: 0.75rem !important;
}
</style>