var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"headers":_vm.headers,"height":_vm.listHeight,"items":_vm.itemList,"items-per-page":-1,"dense":"","fixed-header":"","group-by":"Product.supplier","hide-default-footer":"","item-key":"Product.id","multi-sort":"","sort-by":"Product.product","show-group-by":""},scopedSlots:_vm._u([{key:"item.Product.supplier",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.Product.supplier))])]}},{key:"item.Product.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},[(item.Product.product)?_c('v-chip',{staticClass:"text-no-wrap",attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(item.Product.product))])]):_vm._e()],1)]}},{key:"item.Product.design",fn:function(ref){
var item = ref.item;
return [(item.Product.design)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.design),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.species",fn:function(ref){
var item = ref.item;
return [(item.Product.species)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.species),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.grade",fn:function(ref){
var item = ref.item;
return [(item.Product.grade)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.grade),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.origin_certification",fn:function(ref){
var item = ref.item;
return [(item.Product.origin_certification)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.origin_certification),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.market_certification",fn:function(ref){
var item = ref.item;
return [(item.Product.market_certification)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.market_certification),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.thickness",fn:function(ref){
var item = ref.item;
return [(item.Product.thickness)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.thickness),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.width",fn:function(ref){
var item = ref.item;
return [(item.Product.width)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.width),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.length",fn:function(ref){
var item = ref.item;
return [(item.Product.length)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.length),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.moisture_content",fn:function(ref){
var item = ref.item;
return [(item.Product.moisture_content)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.moisture_content),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.density",fn:function(ref){
var item = ref.item;
return [(item.Product.density)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.density),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.surface",fn:function(ref){
var item = ref.item;
return [(item.Product.surface)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.surface),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.composition",fn:function(ref){
var item = ref.item;
return [(item.Product.composition)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.composition),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.glue",fn:function(ref){
var item = ref.item;
return [(item.Product.glue)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.glue),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.finishing",fn:function(ref){
var item = ref.item;
return [(item.Product.finishing)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.finishing),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.edge",fn:function(ref){
var item = ref.item;
return [(item.Product.edge)?[_c('div',{staticClass:"d-flex flex-column fill-height justify-start"},_vm._l((item.Product.edge),function(option,idx){return _c('v-chip',{key:option,staticClass:"text-no-wrap",class:idx > 0 ? 'my-1' : '',attrs:{"x-small":""}},[_c('span',{directives:[{name:"highlight",rawName:"v-highlight",value:(_vm.highlightTextObject),expression:"highlightTextObject"}]},[_vm._v(_vm._s(option))])])}),1)]:_vm._e()]}},{key:"item.Product.comments",fn:function(ref){
var item = ref.item;
return [(item.Product.comments)?_c('v-chip',{staticClass:"text-no-wrap",attrs:{"x-small":""}},[_vm._v(_vm._s(item.Product.comments))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }